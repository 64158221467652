import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Table, Button } from 'reactstrap';
import { Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import EditRetailerWholesalerForm from './EditRetailerWholesalerForm';
import retailerWholesalerPlaceholderImage from '../assets/img/restaurantimage_unavailable.png'; // Placeholder image
import { getUserFromLocalStorage } from 'utilities/user.js';
import { fetchRetailerWholesalerProfile, deleteRetailerWholesaler, updateRetailerWholesalerProfile } from '../actions/retailerWholesalerProfileActions';

const useStyles = makeStyles(theme => ({
    switchBase: {
        color: theme.palette.secondary.main,
        '&$checked': {
            color: theme.palette.secondary.main,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    checked: {},
    track: {},
}));

const RetailerWholesalerAccount = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { loading, profile, error } = useSelector((state) => state.retailerWholesalerProfile);
    const [editingProfile, setEditingProfile] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const user = getUserFromLocalStorage();
    const vendorId = user?.result?._id;

    useEffect(() => {
        if (vendorId) {
            dispatch(fetchRetailerWholesalerProfile(vendorId));
        }
    }, [dispatch, vendorId]);

    const handleDeleteProfile = () => {
        setShowDeleteModal(true);
    };

    const confirmDeleteProfile = () => {
        dispatch(deleteRetailerWholesaler(vendorId));
        setShowDeleteModal(false);
    };

    const handleEditClick = () => {
        setEditingProfile(profile);
    };

    const handleSave = (updatedProfile) => {
        dispatch(updateRetailerWholesalerProfile(vendorId, updatedProfile));
        setEditingProfile(null);
    };

    const handleCancel = () => {
        setEditingProfile(null);
    };

    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                {error}
                <Button onClick={() => dispatch(fetchRetailerWholesalerProfile(vendorId))} color="primary" style={{ marginLeft: '10px' }}>
                    Retry!
                </Button>
            </div>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Card style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <CardHeader style={{ backgroundColor: '#343a40', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', textAlign: 'center' }}>
                    <h2>Retailer/Wholesaler Profile</h2>
                </CardHeader>
                <CardBody>
                    {editingProfile ? (
                        <EditRetailerWholesalerForm
                            retailerWholesaler={editingProfile}
                            onSave={handleSave}
                            onCancel={handleCancel}
                        />
                    ) : (
                        <Table responsive hover striped>
                            <thead className="thead-dark">
                                <tr>
                                    <th style={{ textAlign: 'left', verticalAlign: 'middle' }}>#</th>
                                    <th style={{ textAlign: 'left', verticalAlign: 'middle' }}>Name</th>
                                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Logo</th>
                                    <th style={{ textAlign: 'left', verticalAlign: 'middle', width: '20%' }}>Location</th>
                                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Activation</th>
                                    <th style={{ textAlign: 'left', verticalAlign: 'middle' }}>ID</th>
                                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>1</td>
                                    <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{profile?.name}</td>
                                    <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px' }}>
                                        <img
                                            src={profile?.imageUrl || retailerWholesalerPlaceholderImage}
                                            alt={profile?.name || "retailerWholesalerPlaceholderImage"}
                                            style={{ width: '100px', height: 'auto', borderRadius: '25px' }}
                                            onError={(e) => e.target.src = retailerWholesalerPlaceholderImage}
                                        />
                                    </td>
                                    <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{profile?.address}</td>
                                    <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px' }}>
                                        <Switch
                                            checked={profile?.isActive}
                                            disabled
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.checked,
                                                track: classes.track,
                                            }}
                                        />
                                    </td>
                                    <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{profile?._id}</td>
                                    <td
                                        style={{
                                            textAlign: 'left',
                                            verticalAlign: 'middle',
                                            padding: '15px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            gap: '5px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Button
                                            color="warning"
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            onClick={handleEditClick}
                                        >
                                            <FaEdit style={{ marginRight: '5px' }} /> Edit
                                        </Button>
                                        <Button
                                            color="danger"
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}
                                            onClick={handleDeleteProfile}
                                        >
                                            <FaTrash style={{ marginRight: '5px' }} /> Delete
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    )}
                </CardBody>
            </Card>
            <Modal
                show={showDeleteModal}
                onHide={toggleDeleteModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirm Delete
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this account? This action can't be undone!
                </Modal.Body>
                <Modal.Footer>
                    <Button color="danger" onClick={confirmDeleteProfile}>Delete</Button>
                    <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RetailerWholesalerAccount;
