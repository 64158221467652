import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";

import newOrderAudioNotification from "./../assets/img/newOrderNotification.mp3";
import { getUserFromLocalStorage } from "../utilities/user.js";

const Dashboard = () => {
   const user = getUserFromLocalStorage()
   const userType = user?.result?.userType;
   const agentId = user?.result?._id;
   const [isLoading, setIsLoading] = useState(true);
   const [trackOrderCount, setTrackOrderCount] = useState([]);
   const [prevCount, setPrevCount] = useState(0);
   const playbackRate = 0.95;

   const [processingCount, setProcessingCount] = useState(0);

   const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
   //   console.log(audioCtx.state, "audioCtx"); //Replaced with Visual Button

   const [play] = useSound(newOrderAudioNotification, {
      volume: 0.2,
      playbackRate,
   });

   const useDashboardStream = (agentId, setIsLoading) => {
      const mountedRef = useRef(false);

      const [processingCountState, setProcessingCountState] = useState(0); // Processing || New Orders Count
      const [dispatchedCountState, setDispatchedCountState] = useState(0); // Dispatched Orders Count
      const [assignedCountState, setAssignedCountState] = useState(0); // Assigned Orders Count
      const [deliveredCountState, setDeliveredCountState] = useState(0); // Delivered Orders Count
      const [codCountState, setCodCountState] = useState(0); // Delivered Orders Count
      const [momoCountState, setMomoCountState] = useState(0); // Delivered Orders Count
      const [trackOrderCountState, setTrackOrderCountState] = useState([]);

      useEffect(() => {
         setProcessingCount(processingCountState);
         setTrackOrderCount(trackOrderCountState);
      }, [processingCountState, trackOrderCountState]);

      const handleMessage = useCallback((e) => {
         if (mountedRef.current) {
            const data = JSON.parse(e.data);
            setTrackOrderCountState(data);
            setIsLoading(false);

            // Update processingCount directly
            const processing = data.filter(
               (order) => order?.delivery_status === "PROCESSING"
            ).length;
            setProcessingCountState(processing);

            // Update dispatchedCount directly
            const dispatched = data.filter(
               (order) => order?.delivery_status === "DISPATCHED"
            ).length;
            setDispatchedCountState(dispatched);

            // Update assignedCount directly
            const assigned = data.filter(
               (order) => order?.delivery_status === "ASSIGNED"
            ).length;
            setAssignedCountState(assigned);

            // Update deliveredCount directly
            const delivered = data.filter(
               (order) => order?.delivery_status === "DELIVERED"
            ).length;
            setDeliveredCountState(delivered);

            // Update codCount directly
            const cod = data.filter(
               (order) => order?.paymentMethod === "COD"
            ).length;
            setCodCountState(cod);

            // Update momoCount directly
            const momo = data.filter(
               (order) => order?.paymentMethod === "MOMO"
            ).length;
            setMomoCountState(momo);
         }
      }, []);

      useEffect(() => {
         mountedRef.current = true;
         const streamUrl =
            userType === "SELLER"
               ? `${process.env.REACT_APP_SERVER_URL}/weborders/vendor_dashboard/${agentId}`
               : `${process.env.REACT_APP_SERVER_URL}/weborders/${agentId}/stream_dashboard`;

         const es = new EventSource(streamUrl);
         es.addEventListener("open", () => {
            console.log("Dashboard stream opened!");
         });
         es.addEventListener("message", handleMessage);

         return () => {
            mountedRef.current = false;
            es.close();
         };
      }, [agentId, handleMessage, userType]);

      return [
         trackOrderCountState,
         processingCountState,
         dispatchedCountState,
         assignedCountState,
         deliveredCountState,
         codCountState,
         momoCountState,
      ];
   };

   const [
      trackOrderCountState,
      processingCountState,
      dispatchedCountState,
      assignedCountState,
      deliveredCountState,
      codCountState,
      momoCountState,
   ] = useDashboardStream(agentId, setIsLoading, userType);

   //Play Sound on New Order
   const handlePlay = useCallback(() => {
      play();
   }, [processingCountState]);

   useEffect(() => {
      if (processingCountState !== prevCount) {
         play();
      }
      setPrevCount(processingCountState);
   }, [processingCountState]);

   const history = useHistory();

   // Dispatched Orders Count
   const dispatched = dispatchedCountState;

   // Delivered Orders
   const delivered = deliveredCountState;

   // Assigned Orders
   const assigned = assignedCountState;

   // COD Payment Orders Count
   const cod = codCountState;

   // MoMo Payment Orders Count
   const momo = momoCountState;

   return (
      <>
         <Container fluid>
            <Row>
               <Col lg="3" sm="6">
                  <Card className="card-stats">
                     <Card.Body style={{ backgroundColor: "#F50076" }}>
                        <Row>
                           <Col xs="5">
                              <div className="icon-big text-center icon-warning">
                                 <i className="nc-icon nc-chart text-warning"></i>
                              </div>
                           </Col>
                           <Col xs="7">
                              <div className="numbers">
                                 <p
                                    className="card-category"
                                    style={{ color: "white", textTransform: "uppercase" }}
                                 >
                                    New Order(s)
                                 </p>
                                 {isLoading ? (
                                    <p
                                       className="card-title"
                                       style={{ color: "white", fontSize: "14px" }}
                                    >
                                       processing...
                                    </p>
                                 ) : (
                                    <Card.Title
                                       as="h4"
                                       style={{
                                          fontWeight: 600,
                                          fontStyle: "italic",
                                          fontSize: 26,
                                          color: "white",
                                       }}
                                    >
                                       {processingCount}
                                    </Card.Title>
                                 )}
                              </div>
                           </Col>
                        </Row>
                     </Card.Body>
                     <Card.Footer>
                        <hr></hr>
                        <div
                           className="stats"
                           style={{
                              cursor: "pointer",
                           }}
                           onClick={() =>
                              history.push(
                                 user?.result?.userType === "SELLER"
                                    ? "/admin/vendorOrdersTable"
                                    : "/admin/paginatedOrdersTable"
                              )
                           }
                        // onClick={() => history.push("/admin/paginatedOrdersTable")}
                        >
                           <i className="fas fa-redo mr-1"></i>
                           Manage Order(s)
                        </div>
                     </Card.Footer>
                  </Card>
               </Col>

               <Col lg="3" sm="6">
                  <Card className="card-stats">
                     <Card.Body style={{ backgroundColor: "#0F353D" }}>
                        <Row>
                           <Col xs="5">
                              <div className="icon-big text-center icon-warning">
                                 {/* <i className="nc-icon nc-light-3 text-success"></i> */}
                                 <i className="nc-icon nc-refresh-02 text-success"></i>
                              </div>
                           </Col>
                           <Col xs="7">
                              <div className="numbers">
                                 <p
                                    className="card-category"
                                    style={{
                                       color: "white",
                                       textTransform: "uppercase",
                                    }}
                                 >
                                    Pending Delivery!
                                 </p>
                                 {isLoading ? (
                                    <p
                                       className="card-title"
                                       style={{ color: "white", fontSize: "14px" }}
                                    >
                                       processing...
                                    </p>
                                 ) : (
                                    <Card.Title
                                       as="h4"
                                       style={{
                                          fontWeight: 400,
                                          fontStyle: "italic",
                                          fontSize: 26,
                                          color: "white",
                                       }}
                                    >
                                       {" "}
                                       {dispatched}
                                    </Card.Title>
                                 )}
                              </div>
                           </Col>
                        </Row>
                     </Card.Body>
                     <Card.Footer>
                        <hr></hr>
                        <div
                           className="stats"
                           style={{
                              cursor: "pointer",
                           }}
                           onClick={() => history.push("/admin/paginatedOrdersTable")}
                        >
                           <i className="far fa-calendar-alt mr-1"></i>
                           Dispatched.
                        </div>
                     </Card.Footer>
                     {/* Assigned Orders Count */}
                     <Card.Body style={{ backgroundColor: "#634A00" }}>
                        <Row>
                           <Col xs="5">
                              <div className="icon-big text-center icon-warning">
                                 {/* Change the icon as needed */}
                                 {/* <i className="nc-icon nc-chart text-warning"></i> */}
                                 <i className="fas fa-shipping-fast text-warning"></i>
                              </div>
                           </Col>
                           <Col xs="7">
                              <div className="numbers">
                                 <p
                                    className="card-category"
                                    style={{ color: "white", textTransform: "uppercase" }}
                                 >
                                    Assigned Orders
                                 </p>
                                 {isLoading ? (
                                    <p
                                       className="card-title"
                                       style={{ color: "white", fontSize: "14px" }}
                                    >
                                       processing...
                                    </p>
                                 ) : (
                                    <Card.Title
                                       as="h4"
                                       style={{
                                          fontWeight: 600,
                                          fontStyle: "italic",
                                          fontSize: 18,
                                          color: "white",
                                       }}
                                    >
                                       {assigned}
                                    </Card.Title>
                                 )}
                              </div>
                           </Col>
                        </Row>
                     </Card.Body>
                     {/* End Assigned Orders Count */}
                  </Card>
               </Col>
               <Col lg="3" sm="6">
                  <Card className="card-stats">
                     <Card.Body style={{ backgroundColor: "#0C7D74" }}>
                        <Row>
                           <Col xs="5">
                              <div className="icon-big text-center icon-warning">
                                 {/* <i className="nc-icon nc-vector text-danger"></i> */}
                                 <i className="nc-icon nc-delivery-fast text-success"></i>
                              </div>
                           </Col>
                           <Col xs="7">
                              <div className="numbers">
                                 <p
                                    className="card-category"
                                    style={{ color: "white", textTransform: "uppercase" }}
                                 >
                                    Delivered
                                 </p>
                                 {isLoading ? (
                                    <p
                                       className="card-title"
                                       style={{ color: "white", fontSize: "14px" }}
                                    >
                                       processing...
                                    </p>
                                 ) : (
                                    <Card.Title
                                       as="h4"
                                       style={{
                                          fontWeight: 600,
                                          fontStyle: "italic",
                                          fontSize: 26,
                                          color: "white",
                                       }}
                                    >
                                       {delivered}
                                    </Card.Title>
                                 )}
                              </div>
                           </Col>
                        </Row>
                     </Card.Body>
                     <Card.Footer>
                        <hr></hr>
                        <div
                           className="stats"
                           style={{
                              cursor: "pointer",
                           }}
                           onClick={() => history.push("/admin/paginatedOrdersTable")}
                        >
                           {/* <i className="far fa-clock-o mr-1"></i> */}
                           <i className="far fa-check-circle mr-1"></i>
                           Overall Delivered.
                        </div>
                     </Card.Footer>
                     {/* Average Delivery Time for Orders Count */}
                     <Card.Body style={{ backgroundColor: "#465973" }}>
                        <Row>
                           <Col xs="5">
                              <div className="icon-big text-center icon-warning">
                                 <i className="nc-icon nc-time-alarm text-warning"></i>
                              </div>
                           </Col>
                           <Col xs="7">
                              <div className="numbers">
                                 <p
                                    className="card-category"
                                    style={{ color: "white", textTransform: "uppercase" }}
                                 >
                                    Avg Delivery Time
                                 </p>
                                 {isLoading ? (
                                    <p
                                       className="card-title"
                                       style={{ color: "white", fontSize: "14px" }}
                                    >
                                       processing...
                                    </p>
                                 ) : (
                                    <Card.Title
                                       as="h4"
                                       style={{
                                          fontWeight: 300,
                                          fontStyle: "italic",
                                          fontSize: 16,
                                          color: "white",
                                       }}
                                    >
                                       0 Hrs
                                    </Card.Title>
                                 )}
                              </div>
                           </Col>
                        </Row>
                     </Card.Body>
                     {/*Average Delivery Time for Orders Orders Count */}
                  </Card>
               </Col>
               <Col lg="3" sm="6">
                  <Card className="card-stats">
                     {/* COD Card */}
                     <Card.Body style={{ backgroundColor: "#1C0E2E" }}>
                        <Row>
                           <Col xs="5">
                              <div className="icon-big text-center icon-warning">
                                 {/* <i className="nc-icon nc-favourite-28 text-primary"></i> */}
                                 {/* <i className="fas fa-money-bill-wave text-primary"></i> */}
                                 <i className="fas fa-hand-holding-usd text-primary"></i>
                              </div>
                           </Col>
                           <Col xs="7">
                              <div className="numbers">
                                 <p className="card-category">COD</p>
                                 {isLoading ? (
                                    <p
                                       className="card-title"
                                       style={{ color: "white", fontSize: "14px" }}
                                    >
                                       processing...
                                    </p>
                                 ) : (
                                    <Card.Title
                                       as="h4"
                                       style={{
                                          fontWeight: 300,
                                          fontStyle: "italic",
                                          fontSize: 26,
                                          color: "white",
                                       }}
                                    >
                                       +{cod}
                                    </Card.Title>
                                 )}
                              </div>
                           </Col>
                        </Row>
                     </Card.Body>
                     {/* End COD Card */}
                     {/* Momo Card */}
                     <Card.Body style={{ backgroundColor: "#FFAE00" }}>
                        <Row>
                           <Col xs="5">
                              <div className="icon-big text-center icon-warning">
                                 <i className="nc-icon nc-bank text-primary"></i>
                              </div>
                           </Col>
                           <Col xs="7">
                              <div className="numbers">
                                 <p
                                    className="card-category"
                                    style={{
                                       color: "#0F353D",
                                    }}
                                 >
                                    MoMo
                                 </p>
                                 {isLoading ? (
                                    <p className="card-title" style={{ fontSize: "14px" }}>
                                       processing...
                                    </p>
                                 ) : (
                                    <Card.Title
                                       as="h4"
                                       style={{
                                          fontWeight: 300,
                                          fontStyle: "italic",
                                          fontSize: 26,
                                          color: "white",
                                       }}
                                    >
                                       +{momo}
                                    </Card.Title>
                                 )}
                              </div>
                           </Col>
                        </Row>
                     </Card.Body>
                     {/* End Momo Card */}
                     <Card.Footer>
                        <hr></hr>
                        <div
                           className="stats"
                           style={{
                              cursor: "pointer",
                           }}
                           onClick={() => history.push("/admin/paginatedOrdersTable")}
                        >
                           <i className="fas fa-redo mr-1"></i>
                           Payment Mode
                        </div>
                     </Card.Footer>
                  </Card>
                  <Card className="card-stats">
                     {audioCtx.state === "suspended" ? (
                        <>
                           <i
                              className="nc-icon nc-bell-55 mr-1"
                              style={{
                                 fontSize: "40px",
                                 color: "red",
                                 cursor: "pointer",
                                 marginTop: "10px",
                                 marginLeft: "10px",
                                 marginRight: "10px",
                                 marginBottom: "10px",
                                 textAlign: "center",
                                 verticalAlign: "middle",
                              }}
                           ></i>
                           <Button
                              className="btn-icon btn-danger text-white "
                              onClick={handlePlay}
                           >
                              Notifications Off {""}
                              <span
                                 role="img"
                                 //  aria-label="Person with lines near mouth"
                                 style={{
                                    fontSize: "19px",
                                    color: "white",
                                    marginLeft: "5px",
                                 }}
                              >
                                 🔇
                              </span>
                           </Button>
                        </>
                     ) : (
                        <>
                           <i
                              className="nc-icon nc-bell-55 mr-1"
                              style={{
                                 fontSize: "40px",
                                 color: "green",
                                 cursor: "pointer",
                                 marginTop: "10px",
                                 marginLeft: "10px",
                                 marginRight: "10px",
                                 marginBottom: "10px",
                                 textAlign: "center",
                                 verticalAlign: "middle",
                              }}
                           ></i>
                           <Button
                              className="btn-icon btn-success text-white "
                              onClick={handlePlay}
                           >
                              Notification On
                              <span
                                 role="img"
                                 //  aria-label="Person with lines near mouth"
                                 style={{
                                    fontSize: "19px",
                                    color: "white",
                                    marginLeft: "5px",
                                 }}
                              >
                                 🔊
                              </span>
                           </Button>
                        </>
                     )}
                  </Card>
               </Col>
            </Row>
            {/* <Row>
               <Col md="12">
                  <Card>
                     <Card.Header>
                        <Card.Title as="h4">
                           Global Sales by Top Locations
                        </Card.Title>
                        <p className="card-category">
                           All products that were shipped
                        </p>
                     </Card.Header>
                     <Card.Body>
                        <Row>
                           <Col md="6">
                              <Table responsive>
                                 <tbody>
                                    <tr>
                                       <td>
                                          <div className="flag">
                                             <img
                                                alt="..."
                                                src={
                                                   require('assets/img/flags/US.png')
                                                      .default
                                                }
                                             ></img>
                                          </div>
                                       </td>
                                       <td>USA</td>
                                       <td className="text-right">2.920</td>
                                       <td className="text-right">53.23%</td>
                                    </tr>
                                    <tr>
                                       <td>
                                          <div className="flag">
                                             <img
                                                alt="..."
                                                src={
                                                   require('assets/img/flags/DE.png')
                                                      .default
                                                }
                                             ></img>
                                          </div>
                                       </td>
                                       <td>Germany</td>
                                       <td className="text-right">1.300</td>
                                       <td className="text-right">20.43%</td>
                                    </tr>
                                    <tr>
                                       <td>
                                          <div className="flag">
                                             <img
                                                alt="..."
                                                src={
                                                   require('assets/img/flags/AU.png')
                                                      .default
                                                }
                                             ></img>
                                          </div>
                                       </td>
                                       <td>Australia</td>
                                       <td className="text-right">760</td>
                                       <td className="text-right">10.35%</td>
                                    </tr>
                                    <tr>
                                       <td>
                                          <div className="flag">
                                             <img
                                                alt="..."
                                                src={
                                                   require('assets/img/flags/GB.png')
                                                      .default
                                                }
                                             ></img>
                                          </div>
                                       </td>
                                       <td>United Kingdom</td>
                                       <td className="text-right">690</td>
                                       <td className="text-right">7.87%</td>
                                    </tr>
                                    <tr>
                                       <td>
                                          <div className="flag">
                                             <img
                                                alt="..."
                                                src={
                                                   require('assets/img/flags/RO.png')
                                                      .default
                                                }
                                             ></img>
                                          </div>
                                       </td>
                                       <td>Romania</td>
                                       <td className="text-right">600</td>
                                       <td className="text-right">5.94%</td>
                                    </tr>
                                    <tr>
                                       <td>
                                          <div className="flag">
                                             <img
                                                alt="..."
                                                src={
                                                   require('assets/img/flags/BR.png')
                                                      .default
                                                }
                                             ></img>
                                          </div>
                                       </td>
                                       <td>Brasil</td>
                                       <td className="text-right">550</td>
                                       <td className="text-right">4.34%</td>
                                    </tr>
                                 </tbody>
                              </Table>
                           </Col>
                           <Col className="ml-auto mr-auto" md="6">
                              <VectorMap
                                 map={'world_mill'}
                                 backgroundColor="transparent"
                                 zoomOnScroll={false}
                                 containerStyle={{
                                    width: '100%',
                                    height: '300px',
                                 }}
                                 containerClassName="map"
                                 regionStyle={{
                                    initial: {
                                       fill: '#e4e4e4',
                                       'fill-opacity': 0.9,
                                       stroke: 'none',
                                       'stroke-width': 0,
                                       'stroke-opacity': 0,
                                    },
                                 }}
                                 series={{
                                    regions: [
                                       {
                                          values: {
                                             AU: 760,
                                             BR: 550,
                                             CA: 120,
                                             DE: 1300,
                                             FR: 540,
                                             GB: 690,
                                             GE: 200,
                                             IN: 200,
                                             RO: 600,
                                             RU: 300,
                                             US: 2920,
                                          },
                                          scale: ['#AAAAAA', '#444444'],
                                          normalizeFunction: 'polynomial',
                                       },
                                    ],
                                 }}
                              />
                           </Col>
                        </Row>
                     </Card.Body>
                  </Card>
               </Col>
            </Row> */}
            {/* <Row>
               <Col md="4">
                  <Card>
                     <Card.Header>
                        <Card.Title as="h4">Email Statistics</Card.Title>
                        <p className="card-category">
                           Last Campaign Performance
                        </p>
                     </Card.Header>
                     <Card.Body>
                        <ChartistGraph
                           className="ct-perfect-fourth"
                           data={{
                              labels: ['40%', '20%', '40%'],
                              series: [40, 20, 40],
                           }}
                           type="Pie"
                        />
                     </Card.Body>
                     <Card.Footer>
                        <div className="legend">
                           <i className="fas fa-circle mr-1 text-info"></i>
                           Open{' '}
                           <i className="fas fa-circle mr-1 text-danger"></i>
                           Bounce{' '}
                           <i className="fas fa-circle mr-1 text-warning"></i>
                           Unsubscribe
                        </div>
                        <hr></hr>
                        <div className="stats">
                           <i className="far fa-clock-o"></i>
                           Campaign sent 2 days ago
                        </div>
                     </Card.Footer>
                  </Card>
               </Col>
               <Col md="8">
                  <Card>
                     <Card.Header>
                        <Card.Title as="h4">Users Behavior</Card.Title>
                        <p className="card-category">24 Hours performance</p>
                     </Card.Header>
                     <Card.Body>
                        <ChartistGraph
                           data={{
                              labels: [
                                 '9:00AM',
                                 '12:00AM',
                                 '3:00PM',
                                 '6:00PM',
                                 '9:00PM',
                                 '12:00PM',
                                 '3:00AM',
                                 '6:00AM',
                                 '',
                              ],
                              series: [
                                 [287, 385, 490, 492, 554, 586, 698, 695, 630],
                                 [67, 152, 143, 240, 287, 335, 435, 437, 470],
                                 [23, 113, 67, 108, 190, 239, 307, 308, 430],
                              ],
                           }}
                           type="Line"
                           options={{
                              low: 0,
                              high: 800,
                              showArea: false,
                              height: '245px',
                              axisX: {
                                 showGrid: false,
                              },
                              lineSmooth: true,
                              showLine: true,
                              showPoint: true,
                              fullWidth: true,
                              chartPadding: {
                                 right: 50,
                              },
                           }}
                           responsiveOptions={[
                              [
                                 'screen and (max-width: 640px)',
                                 {
                                    axisX: {
                                       labelInterpolationFnc: function (value) {
                                          return value[0]
                                       },
                                    },
                                 },
                              ],
                           ]}
                        />
                     </Card.Body>
                     <Card.Footer>
                        <div className="legend">
                           <i className="fas fa-circle mr-1 text-info"></i>
                           Open{' '}
                           <i className="fas fa-circle mr-1 text-danger"></i>
                           Click{' '}
                           <i className="fas fa-circle mr-1 text-warning"></i>
                           Click Second Time
                        </div>
                        <hr></hr>
                        <div className="stats">
                           <i className="fas fa-history"></i>
                           Updated 3 minutes ago
                        </div>
                     </Card.Footer>
                  </Card>
               </Col>
            </Row> */}
            {/* <Row>
               <Col md="6">
                  <Card>
                     <Card.Header>
                        <Card.Title as="h4">2017 Sales</Card.Title>
                        <p className="card-category">
                           All products including Taxes
                        </p>
                     </Card.Header>
                     <Card.Body>
                        <ChartistGraph
                           data={{
                              labels: [
                                 'Jan',
                                 'Feb',
                                 'Mar',
                                 'Apr',
                                 'Mai',
                                 'Jun',
                                 'Jul',
                                 'Aug',
                                 'Sep',
                                 'Oct',
                                 'Nov',
                                 'Dec',
                              ],
                              series: [
                                 [
                                    542, 443, 320, 780, 553, 453, 326, 434, 568,
                                    610, 756, 895,
                                 ],
                                 [
                                    412, 243, 280, 580, 453, 353, 300, 364, 368,
                                    410, 636, 695,
                                 ],
                              ],
                           }}
                           type="Bar"
                           options={{
                              seriesBarDistance: 10,
                              axisX: {
                                 showGrid: false,
                              },
                              height: '245px',
                           }}
                           responsiveOptions={[
                              [
                                 'screen and (max-width: 640px)',
                                 {
                                    seriesBarDistance: 5,
                                    axisX: {
                                       labelInterpolationFnc: function (value) {
                                          return value[0]
                                       },
                                    },
                                 },
                              ],
                           ]}
                        />
                     </Card.Body>
                     <Card.Footer>
                        <div className="legend">
                           <i className="fas fa-circle mr-1 text-info"></i>
                           Tesla Model S{' '}
                           <i className="fas fa-circle mr-1 text-danger"></i>
                           BMW 5 Series
                        </div>
                        <hr></hr>
                        <div className="stats">
                           <i className="fas fa-check"></i>
                           Data information certified
                        </div>
                     </Card.Footer>
                  </Card>
               </Col>
               <Col md="6">
                  <Card className="card-tasks">
                     <Card.Header>
                        <Card.Title as="h4">Tasks</Card.Title>
                        <p className="card-category">Backend development</p>
                     </Card.Header>
                     <Card.Body>
                        <div className="table-full-width">
                           <Table>
                              <tbody>
                                 <tr>
                                    <td>
                                       <Form.Check className="mb-1 pl-0">
                                          <Form.Check.Label>
                                             <Form.Check.Input
                                                defaultValue=""
                                                type="checkbox"
                                             ></Form.Check.Input>
                                             <span className="form-check-sign"></span>
                                          </Form.Check.Label>
                                       </Form.Check>
                                    </td>
                                    <td>
                                       Sign contract for "What are conference
                                       organizers afraid of?"
                                    </td>
                                    <td className="td-actions text-right">
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-688296980">
                                                Edit Task..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="info"
                                          >
                                             <i className="fas fa-edit"></i>
                                          </Button>
                                       </OverlayTrigger>
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-202192706">
                                                Remove..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="danger"
                                          >
                                             <i className="fas fa-times"></i>
                                          </Button>
                                       </OverlayTrigger>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <Form.Check className="mb-1 pl-0">
                                          <Form.Check.Label>
                                             <Form.Check.Input
                                                defaultChecked
                                                defaultValue=""
                                                type="checkbox"
                                             ></Form.Check.Input>
                                             <span className="form-check-sign"></span>
                                          </Form.Check.Label>
                                       </Form.Check>
                                    </td>
                                    <td>
                                       Lines From Great Russian Literature? Or
                                       E-mails From My Boss?
                                    </td>
                                    <td className="td-actions text-right">
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-746544352">
                                                Edit Task..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="info"
                                          >
                                             <i className="fas fa-edit"></i>
                                          </Button>
                                       </OverlayTrigger>
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-743037005">
                                                Remove..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="danger"
                                          >
                                             <i className="fas fa-times"></i>
                                          </Button>
                                       </OverlayTrigger>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <Form.Check className="mb-1 pl-0">
                                          <Form.Check.Label>
                                             <Form.Check.Input
                                                defaultChecked
                                                defaultValue=""
                                                type="checkbox"
                                             ></Form.Check.Input>
                                             <span className="form-check-sign"></span>
                                          </Form.Check.Label>
                                       </Form.Check>
                                    </td>
                                    <td>
                                       Flooded: One year later, assessing what
                                       was lost and what was found when a
                                       ravaging rain swept through metro Detroit
                                    </td>
                                    <td className="td-actions text-right">
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-855684210">
                                                Edit Task..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="info"
                                          >
                                             <i className="fas fa-edit"></i>
                                          </Button>
                                       </OverlayTrigger>
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-242945902">
                                                Remove..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="danger"
                                          >
                                             <i className="fas fa-times"></i>
                                          </Button>
                                       </OverlayTrigger>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <Form.Check className="mb-1 pl-0">
                                          <Form.Check.Label>
                                             <Form.Check.Input
                                                defaultChecked
                                                type="checkbox"
                                             ></Form.Check.Input>
                                             <span className="form-check-sign"></span>
                                          </Form.Check.Label>
                                       </Form.Check>
                                    </td>
                                    <td>
                                       Create 4 Invisible User Experiences you
                                       Never Knew About
                                    </td>
                                    <td className="td-actions text-right">
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-488557184">
                                                Edit Task..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="info"
                                          >
                                             <i className="fas fa-edit"></i>
                                          </Button>
                                       </OverlayTrigger>
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-789597281">
                                                Remove..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="danger"
                                          >
                                             <i className="fas fa-times"></i>
                                          </Button>
                                       </OverlayTrigger>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <Form.Check className="mb-1 pl-0">
                                          <Form.Check.Label>
                                             <Form.Check.Input
                                                defaultValue=""
                                                type="checkbox"
                                             ></Form.Check.Input>
                                             <span className="form-check-sign"></span>
                                          </Form.Check.Label>
                                       </Form.Check>
                                    </td>
                                    <td>
                                       Read "Following makes Medium better"
                                    </td>
                                    <td className="td-actions text-right">
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-521344137">
                                                Edit Task..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="info"
                                          >
                                             <i className="fas fa-edit"></i>
                                          </Button>
                                       </OverlayTrigger>
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-934093947">
                                                Remove..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="danger"
                                          >
                                             <i className="fas fa-times"></i>
                                          </Button>
                                       </OverlayTrigger>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>
                                       <Form.Check className="mb-1 pl-0">
                                          <Form.Check.Label>
                                             <Form.Check.Input
                                                defaultChecked
                                                defaultValue=""
                                                type="checkbox"
                                             ></Form.Check.Input>
                                             <span className="form-check-sign"></span>
                                          </Form.Check.Label>
                                       </Form.Check>
                                    </td>
                                    <td>Unfollow 5 enemies from twitter</td>
                                    <td className="td-actions text-right">
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-97404283">
                                                Edit Task..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="info"
                                          >
                                             <i className="fas fa-edit"></i>
                                          </Button>
                                       </OverlayTrigger>
                                       <OverlayTrigger
                                          overlay={
                                             <Tooltip id="tooltip-888894273">
                                                Remove..
                                             </Tooltip>
                                          }
                                          placement="top"
                                       >
                                          <Button
                                             className="btn-simple btn-link"
                                             type="button"
                                             variant="danger"
                                          >
                                             <i className="fas fa-times"></i>
                                          </Button>
                                       </OverlayTrigger>
                                    </td>
                                 </tr>
                              </tbody>
                           </Table>
                        </div>
                     </Card.Body>
                     <Card.Footer>
                        <hr></hr>
                        <div className="stats">
                           <i className="now-ui-icons loader_refresh spin"></i>
                           Updated 3 minutes ago
                        </div>
                     </Card.Footer>
                  </Card>
               </Col>
            </Row> */}
         </Container>
      </>
   );
};

export default Dashboard;
