import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Form, Col, FormControl } from "react-bootstrap";
import Select from "react-select";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import DOMPurify from "dompurify";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { createListing } from "../../actions/listingsActions";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { getSiUnits } from "../../actions/siUnitActions";
import axios from "axios";
import ImageUploadStyledAlert from "views/Components/ImageUploadStyledAlert";
import { getUserFromLocalStorage } from "utilities/user";

toast.configure();

const ListForm = () => {
  const { siUnits, loading } = useSelector((state) => state.siUnits);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const user = getUserFromLocalStorage();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [inputValue, setValue] = useState("");
  // const [inputFileValue, setInputFileValue] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [units, setUnits] = useState([]);

  //Return policy
  const [useDefaultPolicy, setUseDefaultPolicy] = useState(true);
  const [customPolicy, setCustomPolicy] = useState('');

  // Alert
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [description, setDescription] = useState('');

  const [postData, setPostData] = useState({
    title: "",
    // description: "",
    price: "",
    countInStock: "",
    measurements: "",
    //  category: "",
    // categories: [],
    vendorName: "",
    vendorContactNumber: "",
    vendorAddress: "",
    pic: "",
  });

  const [image, setImage] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const clear = () => {
    setPostData({
      title: "",
      // description: "",
      price: "",
      countInStock: "",
      measurements: "",
      pic: ""
    }),
      setSelectedCategory([]);
    setDescription("");
    setSelectedCategory([]);
    setSelectedUnit([]);
    setCustomPolicy('');
    setDescription("");
    setImage("");
    setIsUploading(false);
    setUploadDone(false);
    setUploadProgress(0);
    setUploadedFileName("");
  };

  useEffect(() => {
    dispatch(getSiUnits());
  }, []);

  useEffect(() => {
    const mappedUnits = siUnits.map((unit) => ({
      value: unit._id,
      label: unit.unit,
      name: unit.unit,
    }));
    setUnits(mappedUnits);
  }, [siUnits]);

  const fetchImageToUpload = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      setIsUploading(true);
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "lusuku-clou");
      data.append("cloud_name", "MM-iCT");

      // percentage count
      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      };

      const abortController = new AbortController();
      try {
        const res = await axios.post(
          process.env.REACT_APP_CLOUDINARY_URL,
          data,
          {
            onUploadProgress,
            signal: abortController.signal,
          }
        );

        const fileData = await res.data;
        setImage(fileData.secure_url);
        setIsUploading(false);
        setUploadDone(true);

        resolve(abortController);
      } catch (err) {
        reject(err);
      }
    });
  }, [image]);

  useEffect(() => {
    let active = true;
    let abortControllerPromise;

    if (image?.name) {
      if (active) {
        abortControllerPromise = fetchImageToUpload();
      }
    }
    return () => {
      active = false;
      if (abortControllerPromise) {
        abortControllerPromise.then((abortController) => {
          abortController.abort();
        });
      }
    };
  }, [image?.name, fetchImageToUpload]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (image?.name && !uploadDone) {
      const abortController = await fetchImageToUpload();
      if (abortController && !abortController.signal.aborted) {
        abortController.abort();
      }
    }

    // Sanitize customPolicy text
    const sanitizedCustomPolicy = DOMPurify.sanitize(customPolicy);

    dispatch(
      createListing(
        {
          ...postData,
          pic: image,
          name: user?.result?.name,
          category: selectedCategory,
          unit_id: selectedUnit.value,
          description: description, // Use the Quill editor content
          useDefaultPolicy: useDefaultPolicy, // Make sure to include this
          customPolicy: sanitizedCustomPolicy,
        },
        user?.result?._id
      )
    );
    clear();

    history.push("/admin/shopCards");
  };

  const loadCategory = async (inputValue) => {
    const res = await fetch("/category/get-all");
    const categories = await res.json();
    if (!inputValue) {
      return categories;
    }

    const filteredCategories = categories.filter((category) =>
      category.category.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredCategories;
  };

  // handle selection
  const handleInputChange = (value) => {
    setValue(value);
  };

  // handle Client Selection
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  // handle Unit Selection
  const handleUnitChange = (value) => {
    setSelectedUnit(value);
  };

  // Check if file is JPEG
  const isJPEG = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        const view = new DataView(buffer);
        const isJPEG =
          view.byteLength >= 2 && view.getUint16(0, false) === 0xffd8; // Check if the file starts with a JPEG SOI marker (0xFFD8)
        resolve(isJPEG);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  // Check if file is a PNG
  const isPNG = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        const view = new DataView(buffer);
        const pngSignature = [137, 80, 78, 71, 13, 10, 26, 10];
        const isPNG = pngSignature.every(
          (byte, index) => view.getUint8(index) === byte
        );
        resolve(isPNG);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const isFileJPEG = await isJPEG(file);
    const isFilePNG = await isPNG(file);
    const fileSizeInMB = file.size / (1024 * 1024);

    if (!isFileJPEG && !isFilePNG) {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "Invalid file type. Only JPG, JPEG, and PNG files are allowed."
      );
      setOpenSnackbar(true);
      setUploadedFileName(""); // Clear the uploaded file name
      return;
    }

    if (fileSizeInMB > 10) {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "File size exceeds 10 MB. Please choose a smaller image."
      );
      setOpenSnackbar(true);
      setUploadedFileName(""); // Clear the uploaded file name
      return;
    }

    // Continue with the upload process
    setImage(file);
    setUploadedFileName(file.name); // Set the uploaded file name
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  //React Quill modules
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ],
  };
  return (
    <>
      <ImageUploadStyledAlert
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />

      <Col md="10">
        <Form
          action=""
          id="RegisterValidation"
          autoComplete="off"
          method=""
          onSubmit={handleSubmit}
        >
          <Card>
            <Card.Header>
              <Card.Title as="h4">Listing Form</Card.Title>
              <p className="card-category">Products ~ Create Listing.</p>
            </Card.Header>
            <Card.Body className="pt-0">
              {/* Product Name */}
              <Col md={6}>
                <Form.Group>
                  <Card.Body>
                    <label>
                      1. Product Name <span className="star">*</span>
                    </label>
                    <Form.Control
                      name="title"
                      type="text"
                      value={postData.title}
                      maxLength="40"
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          title: e.target.value,
                        })
                      }
                    ></Form.Control>
                  </Card.Body>
                  {/* Description */}
                  <Card.Body>
                    <label>
                      2. Product Description
                      <span className="star">*</span>
                    </label>

                    <ReactQuill
                      value={description}
                      onChange={handleDescriptionChange}
                      modules={modules}
                      formats={[
                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                        'list', 'bullet', 'size', 'color', 'background'
                      ]}
                      style={{ height: '220px', marginBottom: '50px' }} // Adjust marginBottom if needed
                      placeholder="Enter product description here..."
                    />
                  </Card.Body>
                  {/* Price */}
                  <Card.Body>
                    <label>
                      3. Item Price
                      <span className="star">*</span>
                    </label>
                    <Form.Control
                      name="price"
                      type="text"
                      pattern="[0-9]*"
                      value={postData.price}
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          price: e.target.value,
                        })
                      }
                    ></Form.Control>
                  </Card.Body>
                  <Card.Body>
                    <label>
                      4. In Stock
                      <span className="star">*</span>
                    </label>
                    <Form.Control
                      name="countInStock"
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={postData.countInStock}
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          countInStock: e.target.value,
                        })
                      }
                    ></Form.Control>
                  </Card.Body>
                  <Card.Body>
                    <Form.Label>5. Select Category</Form.Label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={selectedCategory}
                      getOptionLabel={(e) => e.category}
                      getOptionValue={(e) => e._id}
                      loadOptions={loadCategory}
                      onInputChange={handleInputChange}
                      onChange={handleCategoryChange}
                      isClearable={true}
                      isMulti={true}
                    />
                  </Card.Body>
                  <Card.Body>
                    <label>
                      6. Measurements
                      <span className="star">*</span>
                    </label>
                    <Form.Control
                      name="measurements"
                      type="text"
                      inputMode="numeric"
                      // pattern="[0-9]*"
                      pattern="^\d*(\.\d{0,2})?$"
                      value={postData.measurements}
                      onChange={(e) =>
                        setPostData({
                          ...postData,
                          measurements: e.target.value,
                        })
                      }
                    ></Form.Control>
                  </Card.Body>
                  {/* Vendor Name */}
                  <Card.Body>
                    <Form.Group>
                      <label>7. Vendor Name</label>
                      <Form.Control
                        type="text"
                        value={postData.vendorName}
                        onChange={(e) => setPostData({ ...postData, vendorName: e.target.value })}
                        placeholder="Enter vendor's name"
                      />
                    </Form.Group>
                  </Card.Body>

                  {/* Vendor Contact Number */}
                  <Card.Body>
                    <Form.Group>
                      <label>8. Vendor Primary Contact Number</label>
                      <Form.Control
                        type="text"
                        value={postData.vendorContactNumber}
                        onChange={(e) => setPostData({ ...postData, vendorContactNumber: e.target.value })}
                        placeholder="Enter vendor's contact number..."
                      />
                    </Form.Group>
                  </Card.Body>

                  {/* Vendor Address */}
                  <Card.Body>
                    <Form.Group>
                      <label>9. Vendor's Physical Address</label>
                      <Form.Control
                        type="text"
                        value={postData.vendorAddress}
                        onChange={(e) => setPostData({ ...postData, vendorAddress: e.target.value })}
                        placeholder="Enter vendor's Address..."
                      />
                    </Form.Group>
                  </Card.Body>
                  <Card.Body>
                    <label>
                      10. Measurement Unit
                      <span className="star">*</span>
                    </label>
                    <Select
                      options={units}
                      onChange={handleUnitChange}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Select Measurement Unit..."
                    />
                  </Card.Body>
                </Form.Group>
                {/* Return Policy */}
                <Card.Body>
                  <Form.Group>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <input
                        type="checkbox"
                        id="default-return-policy-checkbox"
                        checked={useDefaultPolicy}
                        onChange={(e) => setUseDefaultPolicy(e.target.checked)}
                        style={{ marginRight: '8px' }}
                      />
                      <label htmlFor="default-return-policy-checkbox">Use default return policy</label>
                    </div>
                    {!useDefaultPolicy && (
                      <>
                        <label>Enter Custom Return Policy<span className="star">*</span></label>
                        <FormControl
                          as="textarea"
                          rows={5}
                          placeholder="Please provide the custom return policy here!"
                          style={{ minHeight: '170px', borderColor: 'orange' }}
                          value={customPolicy}
                          // onChange={handleCustomPolicyChange}
                          onChange={(e) => setCustomPolicy(e.target.value)}
                          required={!useDefaultPolicy}
                        />
                        <div style={{ color: 'orange', marginTop: '5px' }}>Please enter a custom return policy.</div>
                      </>
                    )}
                    {useDefaultPolicy && (
                      <div style={{ color: 'green', marginTop: '2px', fontStyle: "italic", fontSize: "12px" }}>Default return policy will be used!</div>
                    )}
                  </Form.Group>
                </Card.Body>
                <Card.Body className="pt-0">
                  <div style={{ width: "97%", margin: "10px 0" }}>
                    <div>
                      <label
                        htmlFor="image-upload"
                        style={{
                          display: "inline-block",
                          padding: "6px 12px",
                          cursor: "pointer",
                          backgroundColor: "#f8f9fa",
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          fontWeight: 400,
                          color: "#495057",
                          fontSize: "1rem",
                          lineHeight: "1.5",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#e9ecef";
                          e.target.style.borderColor = "#adb5bd";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#f8f9fa";
                          e.target.style.borderColor = "#ced4da";
                        }}
                      >
                        {uploadedFileName || "Upload an image..."}
                      </label>
                      <input
                        id="image-upload"
                        name="image"
                        type="file"
                        accept=".jpg, .png, .jpeg"
                        // onChange={(e) => setImage(e.target.files[0])}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  <div>
                    {uploadedFileName && (
                      <p
                        style={{
                          fontSize: "13px",
                          fontStyle: "italic",
                        }}
                      >
                        File Name: {uploadedFileName}
                      </p>
                    )}
                    {isUploading && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontStyle: "italic",
                        }}
                      >
                        Uploading... {uploadProgress}%
                      </p>
                    )}
                    {uploadDone && !isUploading ? (
                      <p
                        style={{
                          color: "green",
                          fontSize: "13px",
                          fontStyle: "italic",
                        }}
                      >
                        Image Upload Done!
                      </p>
                    ) : null}
                  </div>
                </Card.Body>
              </Col>
            </Card.Body>

            <Card.Body>
              <Card.Footer className="text-right">
                <Button
                  className="btn-fill pull-right"
                  variant="danger"
                  // type="submit"
                  onClick={clear}
                  style={{ marginRight: "5px" }}
                >
                  Clear
                </Button>
                <Button
                  className="btn-fill pull-right"
                  variant="info"
                  type="submit"
                  // onClick={() => {}}
                  // disabled={selectedUnit?.length === 0}
                  disabled={
                    !uploadDone ||
                    selectedCategory.length <= 0 ||
                    selectedUnit?.length === 0
                  }
                >
                  Submit
                </Button>
                <div className="clearfix"></div>
              </Card.Footer>
            </Card.Body>
          </Card>
        </Form>
      </Col>
    </>
  );
  // );
};

export default ListForm;
