// actions/retailerWholesalerSectionActions.js
import { toast } from 'react-toastify';
import * as api from '../api';
import {
    START_LOADING,
    CREATE_SECTION_RW,
    GET_SECTIONS_RW,
    END_LOADING,
    UPDATE_SECTION_RW,
    DELETE_SECTION_REQUEST_RW,
    DELETE_SECTION_SUCCESS_RW,
    DELETE_SECTION_FAILURE_RW,
    GET_RETAILER_WHOLESALERS,
} from '../constants/actionTypes.js';

// Create Section
export const createRetailerWholesalerSection = (sectionData, userId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiCreateRetailerWholesalerSection(sectionData, userId);
        dispatch({ type: CREATE_SECTION_RW, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success(data.message);
    } catch (error) {
        console.log(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || error.message);
        dispatch({ type: END_LOADING });
    }
};

// Fetch RW Sections
export const fetchRetailerWholesalerSections = (vendorId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiFetchRetailerWholesalerSections(vendorId);
        dispatch({ type: GET_SECTIONS_RW, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.error('Error fetching sections:', error);
        toast.error('Failed to fetch sections: ' + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};


// Update Section
export const updateRetailerWholesalerSection = (sectionId, updatedSectionData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiUpdateRetailerWholesalerSection(sectionId, updatedSectionData);
        dispatch({ type: UPDATE_SECTION_RW, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success('Section updated successfully!');
    } catch (error) {
        console.error('Error updating section:', error);
        toast.error('Failed to update section: ' + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Delete Section
export const deleteRetailerWholesalerSectionRequest = () => ({
    type: DELETE_SECTION_REQUEST_RW,
});

export const deleteRetailerWholesalerSectionSuccess = (sectionId, message) => {
    // console.log(message, "messagemessagemessagemessage>>>>>>")
    toast.success(message);
    return {
        type: DELETE_SECTION_SUCCESS_RW,
        payload: sectionId,
    }
};

export const deleteRetailerWholesalerSectionFailure = (error) => ({
    type: DELETE_SECTION_FAILURE_RW,
    payload: error,
});

export const deleteRetailerWholesalerSection = (vendorId, sectionId) => async (dispatch) => {
    dispatch(deleteRetailerWholesalerSectionRequest());
    try {
        const { data } = await api.apiDeleteRetailerWholesalerSection(sectionId);
        // console.log('Delete response:', data);
        dispatch(deleteRetailerWholesalerSectionSuccess(sectionId, data.message));
        dispatch(fetchRetailerWholesalerSections(vendorId)); // Optionally, refetch sections to update state
    } catch (error) {
        dispatch(deleteRetailerWholesalerSectionFailure(error.message));
    }
};

// Fetch Retailers and Wholesalers, conditionally passing vendorId and if the user is a super admin, return the whole list of retailers and wholesalers
export const fetchRetailerWholesalers = (vendorId, isSuperAdmin) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiFetchRetailerWholesalers(vendorId, isSuperAdmin);
        dispatch({ type: GET_RETAILER_WHOLESALERS, payload: data.retailersWholesalers });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.error('Error fetching retailers and wholesalers:', error);
        toast.error('Failed to fetch retailers and wholesalers: ' + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};
