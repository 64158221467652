import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { useHistory } from 'react-router-dom';
import { fetchRestaurantVendorOrderStats } from '../actions/restaurantVendorStatsActions';
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    makeStyles,
    Button,
} from '@material-ui/core';
import {
    Refresh as RefreshIcon,
    Notifications as NotificationsIcon,
    NotificationsOff as NotificationsOffIcon,
    Payment as PaymentIcon,
    LocalShipping as LocalShippingIcon,
    Timer as TimerIcon,
    Assignment as AssignmentIcon,
    CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';

import newRestaurantOrderAudioNotification from "./../assets/img/restaurant_notification_beep.mp3";
import { getUserFromLocalStorage } from '../utilities/user.js';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    card: {
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        position: 'relative',
        boxShadow: '0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.2)',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    cardContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    cardFooter: {
        marginTop: 'auto',
        padding: theme.spacing(1),
        backgroundColor: '#e0e0e0',
        textAlign: 'center',
        borderTop: '1px solid #c0c0c0',
    },
    newOrdersFooter: {
        color: '#A62B1F',
        cursor: 'pointer',
    },
    pendingDeliveryFooter: {
        color: '#1A5AA6',
    },
    deliveredFooter: {
        color: '#1FA62B',
    },
    assignedOrdersFooter: {
        color: '#A6761F',
    },
    avgDeliveryTimeFooter: {
        color: '#1F9AA6',
    },
    paymentMethodsFooter: {
        color: '#A61F5A',
    },
    notificationFooter: {
        color: '#5AA61F',
    },
    icon: {
        fontSize: 40,
    },
    error: {
        color: theme.palette.error.main,
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    notificationButton: {
        backgroundColor: '#ffffff',
        color: '#4caf50',
        '&:hover': {
            backgroundColor: '#4caf50',
            color: '#ffffff',
        },
    },
    notificationButtonOff: {
        backgroundColor: '#ffffff',
        color: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
            color: '#ffffff',
        },
    },
}));

const RestaurantVendorDashboard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { stats, loading, error } = useSelector((state) => state.restaurantVendorStats);

    const [vendorId, setVendorId] = useState(null);
    const [initialLoading, setInitialLoading] = useState(true);
    const [notificationsEnabled, setNotificationsEnabled] = useState(true);

    const notificationSoundRef = useRef(null);
    const user = getUserFromLocalStorage();

    useEffect(() => {
        if (user) {
            const { result } = user;
            setVendorId(result._id); // Ensure this is the _id of the owner
        }
    }, [user]);

    const fetchData = () => {
        if (vendorId) {
            dispatch(fetchRestaurantVendorOrderStats(vendorId)).then(() => setInitialLoading(false));
        }
    };

    useEffect(() => {
        fetchData();
    }, [dispatch, vendorId]);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_SERVER_URL); // Replace with your server URL

        socket.on('newOrder', (order) => {
            if (order.orderItems.some(item => item.vendorId.toString() === vendorId)) {
                fetchData();
                if (notificationsEnabled && notificationSoundRef.current) {
                    notificationSoundRef.current.play();
                }
            }
        });

        socket.on('updateOrder', (order) => {
            if (order.orderItems.some(item => item.vendorId.toString() === vendorId)) {
                fetchData();
            }
        });

        return () => socket.disconnect();
    }, [vendorId, dispatch, notificationsEnabled]);

    const toggleNotifications = () => {
        setNotificationsEnabled(!notificationsEnabled);
    };

    if (initialLoading) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <Container className={classes.root}>
            <audio ref={notificationSoundRef} src={newRestaurantOrderAudioNotification} />
            {loading && (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            )}
            {error && (
                <div>
                    <Typography variant="h6" className={classes.error}>
                        Error: {error}
                    </Typography>
                </div>
            )}
            {!loading && !error && (
                <>
                    <Typography variant="h4" gutterBottom>
                        Restaurant Dashboard
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.card} style={{ backgroundColor: '#E1523D', color: '#ffffff' }}>
                                <CardContent className={classes.cardContent}>
                                    <div className={classes.cardHeader}>
                                        <Typography variant="h6">NEW ORDER(S)</Typography>
                                        <RefreshIcon className={classes.icon} />
                                    </div>
                                    <Typography variant="h3">{stats.newOrders}</Typography>
                                </CardContent>
                                <div className={classes.cardFooter}>
                                    <Typography
                                        variant="body2"
                                        className={classes.newOrdersFooter}
                                        onClick={() => history.push('/admin/resOrdersTable')}
                                    >
                                        Manage Order(s)
                                    </Typography>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.card} style={{ backgroundColor: '#ED8B16', color: '#ffffff' }}>
                                <CardContent className={classes.cardContent}>
                                    <div className={classes.cardHeader}>
                                        <Typography variant="h6">PENDING DELIVERY!</Typography>
                                        <LocalShippingIcon className={classes.icon} />
                                    </div>
                                    <Typography variant="h3">{stats.pendingDelivery}</Typography>
                                </CardContent>
                                <div className={classes.cardFooter}>
                                    <Typography variant="body2" className={classes.pendingDeliveryFooter}>Dispatched</Typography>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.card} style={{ backgroundColor: '#004d40', color: '#ffffff' }}>
                                <CardContent className={classes.cardContent}>
                                    <div className={classes.cardHeader}>
                                        <Typography variant="h6">DELIVERED</Typography>
                                        <CheckCircleIcon className={classes.icon} />
                                    </div>
                                    <Typography variant="h3">{stats.delivered}</Typography>
                                </CardContent>
                                <div className={classes.cardFooter}>
                                    <Typography variant="body2" className={classes.deliveredFooter}>Overall Delivered</Typography>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.card} style={{ backgroundColor: '#795548', color: '#ffffff' }}>
                                <CardContent className={classes.cardContent}>
                                    <div className={classes.cardHeader}>
                                        <Typography variant="h6">ASSIGNED ORDERS</Typography>
                                        <AssignmentIcon className={classes.icon} />
                                    </div>
                                    <Typography variant="h3">{stats.assigned}</Typography>
                                </CardContent>
                                <div className={classes.cardFooter}>
                                    <Typography variant="body2" className={classes.assignedOrdersFooter}>Assigned Orders</Typography>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.card} style={{ backgroundColor: '#607d8b', color: '#ffffff' }}>
                                <CardContent className={classes.cardContent}>
                                    <div className={classes.cardHeader}>
                                        <Typography variant="h6">AVG DELIVERY TIME</Typography>
                                        <TimerIcon className={classes.icon} />
                                    </div>
                                    <Typography variant="h3">0 Hrs</Typography>
                                </CardContent>
                                <div className={classes.cardFooter}>
                                    <Typography variant="body2" className={classes.avgDeliveryTimeFooter}>Average Delivery Time</Typography>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.card} style={{ backgroundColor: '#ffc107', color: '#ffffff' }}>
                                <CardContent className={classes.cardContent}>
                                    <div className={classes.cardHeader}>
                                        <Typography variant="h6">PAYMENT METHODS</Typography>
                                        <PaymentIcon className={classes.icon} />
                                    </div>
                                    {Object.keys(stats.paymentMethods).map((method) => (
                                        <Typography variant="h6" key={method}>
                                            {method}: {stats.paymentMethods[method]}
                                        </Typography>
                                    ))}
                                </CardContent>
                                <div className={classes.cardFooter}>
                                    <Typography variant="body2" className={classes.paymentMethodsFooter}>Payment Methods</Typography>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card className={classes.card} style={{ backgroundColor: '#033B3D', color: '#ffffff' }}>
                                <CardContent className={classes.cardContent}>
                                    <div className={classes.cardHeader}>
                                        <Typography variant="h6">NOTIFICATION</Typography>
                                        {notificationsEnabled ? <NotificationsIcon className={classes.icon} /> : <NotificationsOffIcon className={classes.icon} />}
                                    </div>
                                    <Button
                                        variant="contained"
                                        className={notificationsEnabled ? classes.notificationButton : classes.notificationButtonOff}
                                        startIcon={notificationsEnabled ? <NotificationsIcon /> : <NotificationsOffIcon />}
                                        onClick={toggleNotifications}
                                    >
                                        {notificationsEnabled ? 'Notification On' : 'Notification Off'}
                                    </Button>
                                </CardContent>
                                <div className={classes.cardFooter}>
                                    <Typography variant="body2" className={classes.notificationFooter}>Notification</Typography>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )}
        </Container>
    );
};

export default RestaurantVendorDashboard;
