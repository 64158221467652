import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createRider, updateRider } from "../../actions/ridersAction.js";
import { getUserFromLocalStorage } from "utilities/user.js";

const RiderForm = ({ show, handleClose, riderToEdit }) => {
  const user = getUserFromLocalStorage();
  const [formData, setFormData] = useState({
    mobile: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    carrierType: "Rider",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (riderToEdit) {
      setFormData({
        mobile: riderToEdit.mobile,
        firstName: riderToEdit.firstName,
        lastName: riderToEdit.lastName,
        email: riderToEdit.email,
        carrierType: riderToEdit.carrierType,
        password: "", // Leave password empty for editing
      });
    } else {
      setFormData({
        mobile: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        carrierType: "Rider",
      });
    }
  }, [riderToEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (riderToEdit) {
        await dispatch(updateRider(riderToEdit._id, formData));
        // toast.success("Rider updated successfully!");
      } else {
        await dispatch(createRider(formData, user?.result?._id));
        // toast.success("Rider created successfully!");
      }
      handleClose();
    } catch (error) {
      toast.error(riderToEdit ? "Error updating rider. Please try again." : "Error creating rider. Please try again.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{riderToEdit ? "Edit Rider" : "Create A Rider"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              placeholder="Enter mobile number..."
              name="mobile"
              type="number"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              placeholder="First Name..."
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              placeholder="Last Name..."
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              placeholder="Enter email address..."
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          {!riderToEdit && (
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                placeholder="Enter password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                required={!riderToEdit}
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              name="carrierType"
              value={formData.carrierType}
              onChange={handleChange}
            >
              <option value="Rider">Rider</option>
              <option value="Driver">Driver</option>
              <option value="Cyclist">Cyclist</option>
              <option value="Foot">Foot</option>
            </Form.Control>
          </Form.Group>
          <Button className="btn-fill pull-right mt-2" type="submit" variant="info">
            {riderToEdit ? "Update Rider" : "Create Rider"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RiderForm;