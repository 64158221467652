import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Card,
  Table,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import { Chip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import useStyles from "../Forms/Listings/Listing/orderDetailsStyles";
import {
  getPaginatedOrders,
  getPaginatedOrdersBySearch,
} from "../../actions/mobileOrdersAction";
import PaginatedOrders from "../../views/Forms/Listings/PaginatedOrders.jsx";
import { getUserFromLocalStorage } from "../../utilities/user.js";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PaginatedOrderTable = () => {
  const user = getUserFromLocalStorage();
  const { mobileOrders, isLoading } = useSelector(
    (state) => state.mobileOrders
  );

  const [search, setSearch] = useState("");

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const page = query.get("page") || 1;

  useEffect(() => {
    if (page) {
      dispatch(getPaginatedOrders(page));
    }
  }, [dispatch, page]);

  if (isLoading) {
    return (
      <Spinner
        animation="grow"
        variant="danger"
        style={{
          marginTop: "70px",
          top: "50%",
          left: "50%",
          position: "fixed",
        }}
      />
    );
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (search.length > 0) {
        filterOrders();
      } else {
        dispatch(getPaginatedOrders(page));
        history.replace("/admin/paginatedOrdersTable");
        setSearch("");
      }
    }
  };

  const filterOrders = () => {
    if (search.trim()) {
      dispatch(getPaginatedOrdersBySearch({ search }));
      history.push(
        `/admin/paginatedOrdersTable?searchQuery=${search || "none"}`
      );
    } else {
      dispatch(getPaginatedOrders(page));
      history.replace("/admin/paginatedOrdersTable");
      setSearch("");
    }
  };

  const backToOrders = () => {
    history.push("/admin/paginatedOrdersTable");
    setSearch("");
  };

  const handleInputClear = (e) => {
    setSearch(e.target.value);

    if (e.target.value === "") {
      dispatch(getPaginatedOrders(page));
      history.replace("/admin/paginatedOrdersTable");
    }
  };

  const handleOrderManage = async (order) => {
    try {
      for (const item of order.orderItems) {
        // console.log("Item:", item);
        if (user?.result?.user_role === "SUPERUSER" && item.vendorId && item.vendorId.operationMode === "RETAILER") {
          await history.push(`/admin/vendorOrders-mng/${order._id}`);
          return;
        }
        if (item.vendorId && item.vendorId.operationMode === "RESTAURANT") {
          await history.push(`/admin/restaurantOrders-mng/${order._id}`);
          return;
        }
      }
      await history.push(`/admin/orderDetails/${order._id}`);
    } catch (error) {
      console.error("Error navigating:", error);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="table-with-links">
            <Card.Header>
              <Card.Title as="h4">Mobile App & Web Orders</Card.Title>
              <p className="card-category">Orders to be Processed!</p>
            </Card.Header>
            <Card.Body className="table-full-width">
              <div className={classes.search}>
                <div className="input-group-prepend">
                  <input
                    type="search"
                    id="search"
                    className={classes.searchInput}
                    placeholder="Search Ordered items..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleKeyPress}
                    onInput={handleInputClear}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-behance"
                  onClick={filterOrders}
                  style={{
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                  }}
                >
                  <i
                    className="nc-icon nc-zoom-split"
                    style={{ color: "white" }}
                  ></i>
                </button>
              </div>
              <Table className="table-hover table-striped" responsive>
                <thead>
                  <tr className="danger">
                    <th style={{ color: "black" }} className="text-center">
                      #
                    </th>
                    <th
                      style={{ color: "black", fontStyle: "italic" }}
                      className="text-left"
                    >
                      <strong>Order No.</strong>
                    </th>
                    <th style={{ color: "black", fontStyle: "italic" }}>
                      <strong>Order Src </strong>
                    </th>
                    <th style={{ color: "black", fontStyle: "italic" }}>
                      <strong>Cart Price</strong>
                    </th>
                    <th
                      style={{ color: "black", fontStyle: "italic" }}
                      className="text-right"
                    >
                      <strong>Shipping Zone</strong>
                    </th>
                    <th style={{ color: "black", fontStyle: "italic" }}>
                      <strong>Payment Mode</strong>
                    </th>
                    <th style={{ color: "black", fontStyle: "italic" }}>
                      <strong>Customer's No.</strong>
                    </th>
                    <th
                      style={{ color: "black", fontStyle: "italic" }}
                      className="text-right"
                    >
                      <strong>Customer's Name</strong>
                    </th>
                    <th
                      style={{ color: "black", fontStyle: "italic" }}
                      className="text-right"
                    >
                      <strong>Order Date</strong>
                    </th>

                    <th
                      style={{ color: "black", fontStyle: "italic" }}
                      className="text-right"
                    >
                      <strong>Payment Status</strong>
                    </th>
                    <th style={{ color: "black", fontStyle: "italic" }}>
                      <strong>Delivery Status</strong>
                    </th>

                    <th
                      style={{ color: "black", fontStyle: "italic" }}
                      className="text-right"
                    >
                      <strong>Order Actions</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {mobileOrders?.map((item, index) => (
                    <tr key={item?._id}>
                      <td className="text-center">{index + 1}.</td>
                      <td>
                        {item?.momo_id?.order_number
                          ? item?.momo_id?.order_number
                          : item?._id}
                      </td>

                      <td className="text-right">
                        {item?.orderSrc ? item?.orderSrc : "Agent Call"}
                      </td>

                      <td className="text-right">
                        {item?.totalPrice === 0 ? (
                          <span
                            // className="text-danger"
                            className="text-info"
                          >
                            {item?.itemsPrice
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        ) : (
                          <span className="text-info">
                            {item?.totalPrice
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        )}
                      </td>
                      {item?.shippingAddress?.selectedVillage?.village ? (
                        <td className="text-right">
                          {item?.shippingAddress?.selectedVillage?.village}
                        </td>
                      ) : (
                        <td className="text-right">
                          {item?.shippingAddress?.locationName?.name}
                          {item?.shippingAddress?.address?.address
                            ?.slice(0, 40)
                            ?.concat("...")}
                          {item?.shippingAddress?.description
                            ?.slice(0, 40)
                            ?.concat("...")}
                        </td>
                      )}

                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.paymentMethod}
                      </td>
                      <td className="text-right">
                        {item?.shippingAddress?.customerNumber}
                      </td>
                      <td className="text-right">
                        {item?.client_id?.name}{" "}
                        {item?.customerName?.slice(0, 15)}
                      </td>
                      <td className="text-right">
                        {moment(item?.createdAt).fromNow()}
                      </td>

                      <td
                        className={
                          item?.isPaid && item?.momo_id?.status !== "Failed"
                            ? "text-success text-center"
                            : "text-danger text-center"
                        }
                      >
                        {item?.paymentMethod === "MOMO"
                          ? item?.momo_id?.status
                          : (item?.isPaid && "Paid") || "Not Paid"}
                      </td>
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        <Chip
                          variant="default"
                          label={item?.delivery_status}
                          className={
                            item.delivery_status === "REJECTED"
                              ? classes.red
                              : item?.delivery_status === "DISPATCHED"
                                ? classes.dispatchGrey
                                : item?.delivery_status === "DELIVERED"
                                  ? classes.deliveredColor
                                  : classes.processingOrange
                          }
                        />
                      </td>

                      <td className="td-actions">
                        <ButtonToolbar>
                          {["left"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  Manage
                                </Tooltip>
                              }
                            >
                              <Button
                                size="lg"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOrderManage(item);
                                }}
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>
                          ))}
                        </ButtonToolbar>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <PaginatedOrders page={page} />
    </Container>
  );
};

export default PaginatedOrderTable;
