import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown, Form, Button, Spinner } from 'react-bootstrap';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { MdSportsMotorsports, MdPerson, MdSearch } from "react-icons/md";
import io from 'socket.io-client';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{
            padding: '0.375rem 0.75rem',
            backgroundColor: '#fff',
            border: '1px solid #ced4da',
            borderRadius: '0.25rem',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}
    >
        <span>{children}</span>
        <span style={{ marginLeft: '10px' }}>&#x25bc;</span>
    </div>
));

const RiderSelectionDropdown = ({
    order,
    riderId,
    setRiderId,
    loadingRiders,
    riders,
    assignRestaurantOrderToRider,
    elapsedAssignedTime,
    elapsedDispatchedTime
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredRiders, setFilteredRiders] = useState(riders);
    const [localRiders, setLocalRiders] = useState(riders);
    const selectedRider = localRiders?.find(r => r._id === riderId);

    const updateRiderStatus = useCallback((updatedRiderId, status) => {
        setLocalRiders(prevRiders =>
            prevRiders.map(rider =>
                rider._id === updatedRiderId ? { ...rider, onlineStatus: status } : rider
            )
        );
    }, []);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_SERVER_URL);

        socket.on('riderStatusUpdate', ({ riderId, status }) => {
            updateRiderStatus(riderId, status);
        });

        return () => {
            socket.off('riderStatusUpdate');
            socket.disconnect();
        };
    }, [updateRiderStatus]);

    useEffect(() => {
        const filtered = localRiders?.filter(rider =>
            `${rider.firstName} ${rider.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredRiders(filtered);
    }, [searchTerm, localRiders]);

    useEffect(() => {
        setLocalRiders(riders);
    }, [riders]);

    const renderRiderStatus = (rider) => {
        if (!rider) return null;
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <FiberManualRecordIcon
                    style={{
                        color: rider.onlineStatus === 'Online' ? '#4CAF50' : '#F44336',
                        fontSize: '12px',
                        marginRight: '5px'
                    }}
                />
                {rider.firstName + " " + rider.lastName}
                <span style={{ marginLeft: '5px', color: '#757575', fontSize: '0.9em' }}>
                    ({rider.onlineStatus})
                </span>
            </span>
        );
    };

    const getAssignedRider = () => {
        if (!order?.riderId) return null;
        return localRiders.find(r => r._id === (typeof order.riderId === 'object' ? order.riderId._id : order.riderId));
    };

    return (
        <Form.Group controlId="formRiderSelect" className="shadow p-4" style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)", borderRadius: '8px', backgroundColor: '#f8f9fa' }}>
            <Form.Label style={{ fontWeight: 'bold', color: '#495057' }}>Select Rider</Form.Label>
            <Dropdown className="mb-3">
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" disabled={!!order?.riderId}>
                    {order?.riderId ? renderRiderStatus(getAssignedRider()) :
                        (selectedRider ? renderRiderStatus(selectedRider) :
                            <span style={{ color: '#6c757d' }}><MdPerson style={{ marginRight: '5px' }} /> Select Rider</span>)}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: '100%', maxHeight: '300px', overflowY: 'auto', padding: '10px' }}>
                    <Form.Control
                        type="text"
                        placeholder="Search riders..."
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                        style={{ marginBottom: '10px' }}
                    />
                    {loadingRiders ? (
                        <Dropdown.Item disabled>Searching for riders...</Dropdown.Item>
                    ) : filteredRiders?.length > 0 ? (
                        filteredRiders.map((rider) => (
                            <Dropdown.Item
                                key={rider._id}
                                onClick={() => {
                                    setRiderId(rider._id);
                                    setSearchTerm('');
                                }}
                                active={rider._id === riderId}
                                style={{ display: 'flex', alignItems: 'center', padding: '10px 15px' }}
                            >
                                {renderRiderStatus(rider)}
                            </Dropdown.Item>
                        ))
                    ) : (
                        <Dropdown.Item disabled>No riders found</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>

            {loadingRiders && (
                <div style={{ marginLeft: "2px" }}>
                    <Spinner animation="border" size="sm" />
                </div>
            )}
            {order?.riderId && (
                <div style={{ marginTop: "15px", fontSize: "14px", backgroundColor: '#e9ecef', padding: '10px', borderRadius: '5px' }}>
                    <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                        Assigned Rider:
                    </p>
                    <p style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        {renderRiderStatus(getAssignedRider())}
                        <MdSportsMotorsports
                            style={{
                                marginLeft: "10px",
                                fontSize: "19px",
                                color: "green",
                            }}
                        />
                    </p>
                    {order?.delivery_status === "ASSIGNED" && (
                        <p style={{ fontSize: '12px', color: '#6c757d', marginBottom: '0' }}>
                            Time Elapsed after Assigning: {Math.floor(elapsedAssignedTime / 3600)}h {Math.floor((elapsedAssignedTime % 3600) / 60)}m {elapsedAssignedTime % 60}s
                        </p>
                    )}
                    {order?.delivery_status === "DISPATCHED" && (
                        <p style={{ fontSize: '12px', color: '#6c757d', marginBottom: '0' }}>
                            Time Elapsed from Dispatch: {Math.floor(elapsedDispatchedTime / 3600)}h {Math.floor((elapsedDispatchedTime % 3600) / 60)}m {elapsedDispatchedTime % 60}s
                        </p>
                    )}
                </div>
            )}
            {!order?.riderId && (
                <Button
                    variant="primary"
                    onClick={assignRestaurantOrderToRider}
                    className="mt-3"
                    disabled={!riderId || loadingRiders}
                    style={{
                        visibility: riderId ? "visible" : "hidden",
                        width: '100%',
                        padding: '10px',
                        fontWeight: 'bold'
                    }}
                >
                    Assign Order to Rider
                </Button>
            )}
        </Form.Group>
    );
};

export default RiderSelectionDropdown;